<template>
	<v-container v-if="hasPermission($route.meta.permission)">
		<v-row>
			<v-col>
				<v-form v-model="isFormValid">
					<v-container
						id="UpdateRole"
						class="munjz-bg-grey-dark px-4"
						tag="section"
					>
						<v-row class="ma-0">
							<v-row class="ma-0 munjz-bg-grey ">
								<v-col cols="10" sm="8" class="d-flex align-center me-auto">
									<span
										>Roles & Permissions
										<v-icon>mdi-chevron-right</v-icon></span
									>
									<span class="text-sm-h2 text-h3">Edit Role</span>
								</v-col>
								<v-col cols="2" sm="1" class="d-flex justify-end pa-0">
									<v-btn
										small
										class="blue-grey lighten-4 ma-2"
										fab
										text
										icon
										to="/roles"
										><v-icon>mdi-close</v-icon></v-btn
									>
								</v-col>
							</v-row>
							<v-col cols="12" class="pr-0 pl-0">
								<v-text-field
									v-model="role_name"
									label="Role Name"
									required
									:rules="[val => val.length > 0]"
									outlined
									dense
								></v-text-field>
							</v-col>
							<v-col cols="12" class="pr-0 pl-0">
								<v-textarea
									v-model="role_description"
									label="Role Description"
									outlined
									dense
								></v-textarea>
							</v-col>
							<v-col cols="12" class="pr-0 pl-0">
								<v-autocomplete
									v-model="selected_partner_admins"
									:items="partner_admins"
									required
									item-text="name"
									item-value="id"
									:rules="[val => val.length > 0]"
									label="Select teams member"
									outlined
									deletable-chips
									dense
									multiple
									small-chips
								></v-autocomplete>
							</v-col>
							<v-col cols="12" class="">
								<v-row
									class="ma-0"
									justify="center"
									v-for="(permissions, group, idx) in partner_permissions"
									:key="idx"
								>
									<v-col cols="12" class="pa-0 mt-10 mb-2">
										<div class="d-flex align-center">
											<v-icon small class="me-1" color="primary"
												>mdi-checkbox-blank</v-icon
											>
											<h4 class="ma-0">
												{{ group }}
											</h4>
										</div>
									</v-col>
									<v-col cols="12" class="pa-0">
										<v-checkbox
											class="mt-1 ms-0"
											hide-details
											v-for="permission in permissions"
											v-model="selected_permissions"
											:label="permission.name"
											:value="permission.id"
										></v-checkbox>
									</v-col>
								</v-row>

								<v-col cols="12" class="d-flex justify-center">
									<v-btn
										class="me-0"
										color="primary"
										:disabled="!isFormValid"
										@click="UpdateRole"
										><v-icon left>mdi-update</v-icon> update
									</v-btn>
								</v-col>
							</v-col>

							<v-snackbar
								v-model="snackbar.show"
								centered
								:color="snackbar.color"
							>
								{{ snackbar.text }}

								<template v-slot:action="{ attrs }">
									<v-btn
										color="white"
										text
										v-bind="attrs"
										@click="snackbar.show = false"
									>
										Close
									</v-btn>
								</template>
							</v-snackbar>
						</v-row>
					</v-container>
				</v-form>
			</v-col>
		</v-row>
		<!-- delete Dialog -->
		<!-- <v-dialog
      v-model="deleteDialog"
      overlay-color="munjz-accent-2"
      overlay-opacity="0.7"
      persistent
      max-width="500px"
    >
      <v-card class="rounded-lg">
 <v-container>
            <v-row class="ma-0 d-flex align-center">
              <v-col >
        <h3 >Delete Role</h3>
        </v-col>
         <v-col cols="2">
        <v-btn fab small class="blue-grey lighten-4 float-end" text @click.stop="deleteDialog = false"><v-icon>mdi-close</v-icon></v-cio></v-btn>
         </v-col>
        <v-card-text>
          <b
            >Users must be moved to other roles or they will be deactivated and will not
            be able to enter</b
          ></v-card-text
        >
        <v-row class="ma-0">
         
              <v-col cols="12">
                <v-autocomplete
                  v-model="selected_partner_admins"
                  :items="partner_admins"
                  required
                  		  :rules="[val=>val.length>0]"
                  item-text="name"
                  item-value="id"
                  label="Select Admins"
                  outlined
                  dense
                  hide-details
                  deletable-chips
                  multiple
                  small-chips
                ></v-autocomplete>
              </v-col>
   
     

          <v-radio-group row  v-model="deleteRoleAction">
            <v-radio color="primary primary--text" value=0 label="Move to another role" >
            </v-radio>
            <v-radio color="primary primary--textg" value=1 label="Deactivate" >
          
            </v-radio>
          </v-radio-group>
          </v-row>
              
                  <v-row class="ma-0">
                                <v-col cols="8" v-if="deleteRoleAction==0" >
                <v-autocomplete
                  v-model="selectedMoveToRole"
                  :items="role.permissions"
                  required
                  item-text="name"
                  item-value="id"
                  label="Move to role"
                  outlined
                  dense
                  deletable-chips
                  multiple
                  small-chips
                ></v-autocomplete>
              </v-col>
                  <v-col cols="12">
                   <v-btn color="red" @click="deactivateUser"><v-icon>mdi-delete-outline</v-icon>{{deleteRoleAction==1?'Move to role':'Deactivate users'}}</v-btn>
                   </v-col>
                   </v-row>
               
                 </v-row>
          </v-container>
      </v-card>
    </v-dialog> -->
	</v-container>
	<v-container fluid v-else class="pa-10 " id="regular-tables" tag="section">
		<v-row>
			<v-col cols="12" class="d-flex justify-center">
				<v-card flat>
					<v-card-title
						><v-card class="red">
							<h1 class="red pa-6">
								You Don't have Permission to view this page !
							</h1></v-card
						></v-card-title
					>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
export default {
	data() {
		return {
			selectedMoveToRole: [],
			deleteRoleAction: null,
			deleteDialog: false,
			editEnabled: false,
			snackbar: {
				text: "",
				color: "",
				show: false
			},
			isFormValid: false,
			isLoading: false,
			role: null,
			role_id: 0,
			role_name: "",
			role_description: "",
			partner_permissions: [],
			selected_permissions: [],
			partner_admins: [],
			selected_partner_admins: [],
			user_id: localStorage.getItem("userid"),
			token: localStorage.getItem("token")
		};
	},
	mounted() {
		this.getAdminPermissions();
		this.getPermissions();
		this.getPartnerAdmins();
		this.getRoleDetail();
	},

	methods: {
		deactivateUser() {
			if (this.deleteRoleAction === 1) {
			}
		},
		editMode() {
			this.editEnabled = !this.editEnabled;
		},
		showSnackbar(message, color) {
			this.snackbar.show = true;
			this.snackbar.text = message;
			this.snackbar.color = color;
		},
		async getRoleDetail() {
			axios
				.get("/api/v3/b2b/roles/detail", {
					headers: { "X-Authorization": this.token },
					params: {
						role_id: this.$route.params.id
					}
				})
				.then(res => {
					this.role = res.data.partner_role;
					this.role_name = this.role.name;
					this.role_id = this.role.id;
					this.role_description = this.role.description;
					this.role.permissions.forEach(permission => {
						this.selected_permissions.push(permission.id);
					});

					this.role.users.forEach(user => {
						this.selected_partner_admins.push(user.id);
					});
				})
				.catch(err => console.log(err));
		},
		getPermissions() {
			//  alert("user"+this.user_id)
			axios
				.get("/api/v3/b2b/permissions/list", {
					headers: { "X-Authorization": this.token }
				})
				.then(res => {
					this.partner_permissions = res.data.permissions;
				})
				.catch(err => console.log(err));
		},
		getPartnerAdmins() {
			//  alert("user"+this.user_id)
			axios
				.get("/api/v3/get-partner-admins", {
					headers: { "X-Authorization": this.token },
					params: {
						user_id: this.user_id
					}
				})
				.then(res => {
					this.partner_admins = res.data.teams;
				})
				.catch(err => console.log(err));
		},
		UpdateRole() {
			axios
				.post(
					"/api/v3/b2b/roles/update",
					{
						role_id: this.role_id,
						role_name: this.role_name,
						role_description: this.role_description,
						selected_permissions: this.selected_permissions,
						selected_partner_admins: this.selected_partner_admins
					},
					{
						headers: { "X-Authorization": this.token }
					}
				)
				.then(response => {
					if (response.data.status == 200) {
						this.partner_admins.push(response.data.new_admin);
						this.showSnackbar(response.data.message, "green");
						this.$router.push({
							path: `/roles`
						});
					} else {
						// this.errors = response.data.errors;
						// this.showSnackbar(response.data.message, "red");
					}
				})
				.catch(error => {
					if (error.response.data.status_code) {
						this.$router.push("/");
					}
					this.showSnackbar("Something went wrong", "red");
				});
		}
	}
};
</script>
